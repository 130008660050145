var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('strong',[_vm._v("Defina abaixo quais as colunas que terão visibilidade em tela, definindo o tipo de dados, formatação e alinhamento")]),_c('table',{staticClass:"table table-bordered table-sm"},[_vm._m(0),_c('tbody',[(_vm.colunas.length <= 0)?_c('tr',[_vm._m(1)]):_vm._e(),_vm._l((_vm.colunas),function(input){return _c('tr',[_c('td',[_c('div',{staticClass:"ncform-input __ncform-control"},[_c('div',{staticClass:"el-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(input.field),expression:"input.field"}],staticClass:"el-input__inner",attrs:{"type":"text","autocomplete":"off","placeholder":"Nome da coluna na consulta"},domProps:{"value":(input.field)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(input, "field", $event.target.value)}}})])])]),_c('td',[_c('div',{staticClass:"ncform-input __ncform-control"},[_c('div',{staticClass:"el-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(input.label),expression:"input.label"}],staticClass:"el-input__inner",attrs:{"type":"text","autocomplete":"off","placeholder":"Label de exibição"},domProps:{"value":(input.label)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(input, "label", $event.target.value)}}})])])]),_c('td',[_c('div',{staticClass:"ncform-input __ncform-control"},[_c('div',{staticClass:"el-input"},[_c('NcSelect',{attrs:{"config":{
                placeholder: 'Tipo de dados',
                enumSource: [
                  {
                    label: 'Texto plano',
                    value: 'STRING'
                  },
                  {
                    label: 'Valor monetário',
                    value: 'CURRENCY'
                  },
                  {
                    label: 'Percentual',
                    value: 'PERCENT'
                  },
                  {
                    label: 'Número',
                    value: 'NUMBER'
                  },
                  {
                    label: 'Número inteiro',
                    value: 'INTEGER'
                  }
                ]
              }},model:{value:(input.tipo),callback:function ($$v) {_vm.$set(input, "tipo", $$v)},expression:"input.tipo"}})],1)])]),_c('td',[_c('div',{staticClass:"ncform-input __ncform-control"},[_c('div',{staticClass:"el-input"},[_c('NcSelect',{attrs:{"config":{
                placeholder: 'Alinhamento',
                required: true,
                enumSource: [
                  {
                    label: 'Esquerda',
                    value: 'left'
                  },
                  {
                    label: 'Centro',
                    value: 'center'
                  },
                  {
                    label: 'Direita',
                    value: 'right'
                  } ]
              }},model:{value:(input.align),callback:function ($$v) {_vm.$set(input, "align", $$v)},expression:"input.align"}})],1)])]),_c('td',[_c('CButton',{attrs:{"color":"danger","size":"sm"},on:{"click":function($event){return _vm.removerColuna(input)}}},[_c('fa-icon',{attrs:{"icon":"trash-alt"}})],1)],1)])})],2)]),_c('CButton',{attrs:{"color":"primary","size":"sm"},on:{"click":_vm.adicionarColuna}},[_c('fa-icon',{attrs:{"icon":"plus"}}),_vm._v(" Adicionar coluna ")],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Nome da coluna")]),_c('th',[_vm._v("Label")]),_c('th',[_vm._v("Tipo")]),_c('th',[_vm._v("Alinhamento")]),_c('th',[_vm._v("#")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"text-center",attrs:{"colspan":"5"}},[_vm._v(" Nenhuma coluna criada, faça a inclusão pelo botão de "),_c('strong',[_vm._v("Adicionar coluna")]),_vm._v(" abaixo ")])}]

export { render, staticRenderFns }