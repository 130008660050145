<template>
  <div class="json-editor-container">
    <JsonEditor
        :options="options"
        :objData="modelVal"
        v-model="modelVal" >
    </JsonEditor>
  </div>
</template>

<script>

import ncformCommon from '@ncform/ncform-common';

export default {
  name: "Jsonblock",
  mixins: [
    ncformCommon.mixins.vue.controlMixin
  ],
  data() {
    return {
      options: {
        confirmText: 'Confirmar',
        cancelText: 'Cancelar',
      }
    }
  },
}
</script>

<style scoped>
  .json-editor-container {
    border: 1px solid #ccc;
    border-radius: 4px;
  }
</style>