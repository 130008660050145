import Vue from 'vue';
import VueSweetAlert from 'vue-sweetalert2';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import VModal from 'vue-js-modal';
import SimpleVueValidation from 'simple-vue-validator';
import VueSelect from 'vue-select';
import 'vuetify/dist/vuetify.min.css';
import CoreuiVue from '@coreui/vue'

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
Vue.use(Vuetify);

import App from "./App.vue";
import router from "./router";
import { unregisterServiceWorker } from "./registerServiceWorker";

import CKEditor from "@ckeditor/ckeditor5-vue";

import Loading from "@/components/Loading";
import LoadingMessage from "@/components/LoadingMessage";
import LogAlteracaoDados from "@/components/Log/LogAlteracaoDados";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "./components/icons";
import "sweetalert2/dist/sweetalert2.min.css";

unregisterServiceWorker();

Vue.config.productionTip = false;

import { VuejsDatatableFactory } from "vuejs-datatable";
Vue.use(VuejsDatatableFactory);

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import money from "v-money";
Vue.use(money, { precision: 2 });

import Datetime from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
Vue.use(Datetime);

import InputPercent from "./components/InputPercent";
Vue.use(InputPercent);

import InputMoney from "./components/InputMoney";
Vue.use(InputMoney);

import InputDatePicker from "./components/InputDatePicker";
Vue.use(InputDatePicker);

import ImagePicker from "./components/ImagePicker";
Vue.use(ImagePicker);

import ToggleButton from "vue-js-toggle-button";
Vue.use(ToggleButton);

import JsonEditor from "vue-json-edit";
Vue.use(JsonEditor);

import vueNcform from '@ncform/ncform';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ncformStdComps from '@ncform/ncform-theme-elementui';
// import FormColorPicker from './components/form/FormColorPicker';
import FormInputColorPicker from './components/form/FormInputColorPicker';
import Codeblock from './components/form/ncform/Codeblock';
import Jsonblock from './components/form/ncform/Jsonblock';
import WidgetTipo from './components/form/ncform/WidgetTipo';

Vue.use(Element);
Vue.use(vueNcform, {
  extComponents: Object.assign(ncformStdComps, {
    FormInputColorPicker,
    Codeblock,
    Jsonblock,
    WidgetTipo,
    InputPercent,
    InputMoney,
    InputDatePicker,
    ImagePicker
  }),
  lang: 'pt-br'
});


import { iconsSet as icons } from './assets/icons/icons.js'

import VueMarkdown from "vue-markdown";

import { dateFilter, dateTimeFilter } from "./filters/dateFilter";
import { monetary } from "./filters/monetary";
import { boolean, booleanHtml } from "./filters/text";
import VueQRCodeComponent from 'vue-qrcode-component';
import SelectModelPicker from '../src/components/crud/SelectModelPicker';

Vue.filter("date", dateFilter);
Vue.filter("datetime", dateTimeFilter);
Vue.filter("monetary", monetary);
Vue.filter("boolean", boolean);
Vue.filter("booleanHtml", booleanHtml);

Vue.use(VModal);
Vue.use(SimpleVueValidation);
Vue.use(VueSweetAlert);

Vue.use(CKEditor);
Vue.use(CoreuiVue);

// import "@/assets/css/style.css";
import "@/assets/css/components.css";

Vue.component('v-select', VueSelect);
Vue.component('Loading', Loading);
Vue.component('LoadingMessage', LoadingMessage);
Vue.component('LogAlteracaoDados', LogAlteracaoDados);
Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('input-percent', InputPercent);
Vue.component('vue-markdown', VueMarkdown);
Vue.component('qr-code', VueQRCodeComponent);
Vue.component('SelectModelPicker', SelectModelPicker);

// import '@/assets/css/style.css';
import '@/assets/css/components.css';

import config from './config'
import VueGtm from '@gtm-support/vue2-gtm';
Vue.use(
  VueGtm,
  {
    id: config.gtmToken,
    vueRouter: router,
  }
);

import configureApplication from "./configureApplication";
configureApplication(Vue);

window.swal = Swal;

new Vue({
  router,
  icons,
  render: (h) => h(App),
}).$mount("#app");
