<template>
  <div>
    <strong>Defina abaixo quais as grupos que terão visibilidade em tela e seu label de exibição</strong>
    <table class="table table-bordered table-sm">
      <thead>
      <tr>
        <th>Agregador</th>
        <th>Label</th>
        <th>#</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="grupos.length <= 0">
        <td colspan="4" class="text-center">
          Nenhum agregador, faça a inclusão pelo botão de <strong>Adicionar agregador</strong> abaixo
        </td>
      </tr>
      <tr v-for="input in grupos">
        <td>
          <div class="ncform-input __ncform-control">
            <div class="el-input">
              <input
                v-model="input.agregador"
                type="text"
                autocomplete="off"
                placeholder="Valor do campo agregador"
                class="el-input__inner"
              />
            </div>
          </div>
        </td>
        <td>
          <div class="ncform-input __ncform-control">
            <div class="el-input">
              <input
                v-model="input.label"
                type="text"
                autocomplete="off"
                placeholder="Label de exibição"
                class="el-input__inner"
              />
            </div>
          </div>
        </td>
        <td>
          <CButton color="danger" size="sm" v-on:click="removerColuna(input)">
            <fa-icon icon="trash-alt" />
          </CButton>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="mb-2">
      <CButton color="primary" size="sm" v-on:click="adicionarColuna">
        <fa-icon icon="plus" />
        Adicionar coluna
      </CButton>
    </div>
  </div>
</template>

<script>

import {select as NcSelect} from '@ncform/ncform-theme-elementui/dist/ncformStdComps'

export default {
  name: "EstruturaWidgetGrafico",
  components: {
    NcSelect
  },
  props: [
    'value',
    'formData'
  ],
  data() {
    return {
      grupos: [],
      schema: {
        agregador: "",
        label: ""
      }
    }
  },
  methods: {
    adicionarColuna () {
      this.grupos.push({...this.schema});
    },
    removerColuna (input) {
      this.grupos.splice(this.grupos.indexOf(input), 1);
    }
  },
  beforeMount() {
    // Se a estrutura é a de TABELA, atribui o valor aos dados
    if (typeof this.value === 'object'
        && this.value !== null
        && this.value.grupos !== undefined
    ) {
      this.grupos = this.value.grupos;
      return;
    }

    // Tratativa para modificar a estrutura para a correta de tabela
    this.grupos = [];
  },
  watch: {
    grupos (estrutura) {
      this.$emit('input', {grupos:estrutura});
    }
  }
}
</script>