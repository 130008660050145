<template>
  <div>
    <div v-if="formData.tipo === 'TABELA'">
      <EstruturaWidgetTabela
        :formData="formData"
        v-model="modelVal"
      />
    </div>
    <div v-else-if="formData.tipo === 'CONTEUDO'">
      <EstruturaConteudo
        :formData="formData"
        v-model="modelVal"
      />
    </div>
    <div v-else-if="formData.tipo === 'IFRAME'">
      <label title="Titulo" class="el-form-item__label">URL do Iframe</label>
      <input type="text" autocomplete="off" class="el-input__inner" v-model="modelVal">
    </div>
    <div v-else-if="formData.tipo.startsWith('GRAFICO')">
      <EstruturaWidgetGrafico
        :formData="formData"
        v-model="modelVal"
      />
    </div>
    <img
      v-if="!!helpers[formData.tipo]"
      :src="helpers[formData.tipo]"
      class="w-100"
    />
  </div>
</template>

<script>

import clone from "just-clone";
import ncformCommon from '@ncform/ncform-common';
import EstruturaWidgetTabela from "@/components/form/ncform/WidgetTipo/EstruturaWidgetTabela.vue";
import EstruturaWidgetGrafico from "@/components/form/ncform/WidgetTipo/EstruturaWidgetGrafico.vue";
import EstruturaConteudo from "@/components/form/ncform/WidgetTipo/EstruturaConteudo.vue";

import helperWidgetGrafico from "../../../assets/images/como-criar-estrutura-widget-grafico.jpg";
import helperWidgetCard from "../../../assets/images/como-criar-widget-card.jpg";

const schemaGrafico = {
  grupos: [
    {
      agregador: "anterior",
      label: "Anterior"
    },
    {
      agregador: "atual",
      label: "Atual"
    }
  ]
};

export default {
  name: "WidgetTipo",
  components: {
    EstruturaConteudo,
    EstruturaWidgetGrafico,
    EstruturaWidgetTabela,
  },
  mixins: [
    ncformCommon.mixins.vue.controlMixin
  ],
  data() {
    return {
      helpers: {
        SIMPLE_CARD: helperWidgetCard,
        GRAFICO_COMPARE: helperWidgetGrafico,
        GRAFICO_PIZZA: helperWidgetGrafico,
        GRAFICO_BARRA: helperWidgetGrafico,
        GRAFICO_DATE: helperWidgetGrafico,
      },
      tipos: {
        TABELA: { colunas: [] },
        CONTEUDO: "",
        IFRAME: "",
        SIMPLE_CARD: "",
        GRAFICO_COMPARE: {...schemaGrafico},
        GRAFICO_PIZZA: {...schemaGrafico},
        GRAFICO_BARRA: {...schemaGrafico},
        GRAFICO_DATE: {...schemaGrafico},
      },
    }
  },
  methods: {
    setInitialFieldValue(tipo) {
      if (this.tipos[tipo] !== undefined) {
        // Só altera se tipos diferentes
        if (typeof this.value !== typeof this.modelVal) {
          const novoValor = this.tipos[tipo] || "";
          this.modelVal = typeof this.value === 'string'
            ? ""
            : clone(novoValor);
        }
        return;
      }
      this.modelVal = {};
    }
  },
  watch: {
    "formData.tipo" (tipo) {
      this.setInitialFieldValue(tipo);
    }
  },
  beforeMount() {
    this.setInitialFieldValue(this.formData.tipo || 'TABELA');
  }
}
</script>

<style scoped>
  .json-editor-container {
    border: 1px solid #ccc;
    border-radius: 4px;
  }
</style>
