<template>
  <div>
    <strong>Defina abaixo quais as colunas que terão visibilidade em tela, definindo o tipo de dados, formatação e alinhamento</strong>
    <table class="table table-bordered table-sm">
      <thead>
      <tr>
        <th>Nome da coluna</th>
        <th>Label</th>
        <th>Tipo</th>
        <th>Alinhamento</th>
        <th>#</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="colunas.length <= 0">
        <td colspan="5" class="text-center">
          Nenhuma coluna criada, faça a inclusão pelo botão de <strong>Adicionar coluna</strong> abaixo
        </td>
      </tr>
      <tr v-for="input in colunas">
        <td>
          <div class="ncform-input __ncform-control">
            <div class="el-input">
              <input
                v-model="input.field"
                type="text"
                autocomplete="off"
                placeholder="Nome da coluna na consulta"
                class="el-input__inner"
              />
            </div>
          </div>
        </td>
        <td>
          <div class="ncform-input __ncform-control">
            <div class="el-input">
              <input
                v-model="input.label"
                type="text"
                autocomplete="off"
                placeholder="Label de exibição"
                class="el-input__inner"
              />
            </div>
          </div>
        </td>
        <td>
          <div class="ncform-input __ncform-control">
            <div class="el-input">
              <NcSelect
                v-model="input.tipo"
                :config="{
                  placeholder: 'Tipo de dados',
                  enumSource: [
                    {
                      label: 'Texto plano',
                      value: 'STRING'
                    },
                    {
                      label: 'Valor monetário',
                      value: 'CURRENCY'
                    },
                    {
                      label: 'Percentual',
                      value: 'PERCENT'
                    },
                    {
                      label: 'Número',
                      value: 'NUMBER'
                    },
                    {
                      label: 'Número inteiro',
                      value: 'INTEGER'
                    }
                  ]
                }"
              />
            </div>
          </div>
        </td>
        <td>
          <div class="ncform-input __ncform-control">
            <div class="el-input">
              <NcSelect
                v-model="input.align"
                :config="{
                  placeholder: 'Alinhamento',
                  required: true,
                  enumSource: [
                    {
                      label: 'Esquerda',
                      value: 'left'
                    },
                    {
                      label: 'Centro',
                      value: 'center'
                    },
                    {
                      label: 'Direita',
                      value: 'right'
                    },
                  ]
                }"
              />
            </div>
          </div>
        </td>
        <td>
          <CButton color="danger" size="sm" v-on:click="removerColuna(input)">
            <fa-icon icon="trash-alt" />
          </CButton>
        </td>
      </tr>
      </tbody>
    </table>
    <CButton color="primary" size="sm" v-on:click="adicionarColuna">
      <fa-icon icon="plus" />
      Adicionar coluna
    </CButton>
  </div>
</template>

<script>

import {select as NcSelect} from '@ncform/ncform-theme-elementui/dist/ncformStdComps'

export default {
  name: "EstruturaWidgetTabela",
  components: {
    NcSelect
  },
  props: [
    'value',
    'formData'
  ],
  data() {
    return {
      colunas: [],
      schema: {
        field: "",
        label: "",
        tipo: "STRING",
        align: "left"
      }
    }
  },
  methods: {
    adicionarColuna () {
      this.colunas.push({...this.schema});
    },
    removerColuna (input) {
      this.colunas.splice(this.colunas.indexOf(input), 1);
    }
  },
  beforeMount() {
    // Se a estrutura é a de TABELA, atribui o valor aos dados
    if (typeof this.value === 'object'
        && this.value !== null
        && this.value.colunas !== undefined
    ) {
      this.colunas = this.value.colunas;
      return;
    }

    // Tratativa para modificar a estrutura para a correta de tabela
    this.colunas = [];
  },
  watch: {
    colunas (estrutura) {
      this.$emit('input', {colunas: estrutura});
    }
  }
}
</script>